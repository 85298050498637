<template>
    <div>
        <b-card >

            <div class="d-flex justify-content-between">
                <div style="font-weight: bolder; font-size: 18px;">
                    {{ $t('print.message.system_messages') }}
                </div>
                <div>
                    <b-button class="mr-2" variant="primary" size="sm" @click="markAllAsRead">
                        {{ $t('print.message.mark_all_as_read') }}
                    </b-button>
                    <b-button variant="primary" size="sm" @click="changeNotificationsSettings">
                        <feather-icon
                            icon="SettingsIcon"
                            size="15"
                        />
                    </b-button>
                </div>
            </div>
            <div v-for="(message, index) in messages" :key="'message_number_' + message.id + '_' + index">
                <MessageCard :message="message" />
            </div>
        </b-card>

        <ChanageNotificationSettings ref="notificationsSettings"/>
    </div>
</template>
<script>
    import { BCard, BButton } from 'bootstrap-vue'
    import MessageCard from '@/views/SystemMessages/components/MessageCard.vue'
    import * as Sentry from '@sentry/vue'
    import ChanageNotificationSettings from '@/views/SystemMessages/components/ChanageNotificationSettings.vue'

    export default {
        components: {ChanageNotificationSettings, BCard, MessageCard, BButton},
        data() {
            return {
                messages:[],
                showLoader:false
            }
        },
        methods:{
            changeNotificationsSettings() {
                this.$refs.notificationsSettings.show()
            },
            async markAllAsRead() {
                try {
                    await this.$http.put('/api/client/v1/messages/read/mark_read/all')
                    this.$store.dispatch('messages/set_messages')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    await this.loadMessagesReduced()
                } finally {
                    this.showLoader = false
                }
            },
            async loadMessagesReduced() {
                try {
                    this.showLoader = true
                    
                    const response = await this.$http.get('/api/client/v1/messages/all/reduced/')
                    this.messages = response.data.messages ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(`${this.$t('print.error.on_load_data')}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        computed:{
            watchStoreUnreadMessages() {
                return this.$store.getters['messages/getUnreadMessagesCount']
            }
        },
        async mounted() {
            await this.loadData()
        },
        watch: {
            //if user is on message list, this trigger reload for updating read on the list
            'watchStoreUnreadMessages'() {
                this.loadData()
            }
        }
    }
</script>
<style scoped>

</style>