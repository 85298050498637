<template>
    <b-modal v-model="showModal" hide-footer>
        <div class="mt-2 text-center">
            {{$t('print.message.choose_notifications')}}.
        </div>

        <div class="d-flex justify-content-center mt-1 mb-3">
            <div class="ml- mt-1">
                <div class="d-flex" style="margin-bottom: 5px;">
                    <div class="d-flex" style="width: 200px;">
                        <div>
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="BookIcon"/>
                        </div>
                        {{$t('print.message.changes_backlog')}}
                    </div>
                    <b-form-checkbox v-model="emailNotifications.changelog" switch />
                </div>

                <div class="d-flex"  style="margin-bottom: 5px;">
                    <div class="d-flex" style="width: 200px;">
                        <div>
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="ShieldIcon"/>
                        </div>
                        {{$t('print.message.warning')}}
                    </div>
                    <b-form-checkbox v-model="emailNotifications.warning" switch />
                </div>

                <div class="d-flex"  style="margin-bottom: 5px;">
                    <div class="d-flex" style="width: 200px;">
                        <div>
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="MailIcon"/>
                        </div>
                        {{$t('print.message.message')}}
                    </div>
                    <b-form-checkbox v-model="emailNotifications.messages" switch />
                </div>

                <div class="d-flex">
                    <div class="d-flex" style="width: 200px;">

                        <div>
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="DollarSignIcon"/>
                        </div>
                        {{$t('print.message.finances')}}
                    </div>
                    <b-form-checkbox v-model="emailNotifications.finances" switch />
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="changeNotifications">{{$t('print.message.accept_changes')}}</b-button>
        </div>
    </b-modal>
</template>

<script>
    import {BFormCheckbox, BModal, BButton} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormCheckbox,
            BModal,
            BButton
        },
        data() {
            return {
                showModal: false,
                emailNotifications: {
                    changelog: false,
                    warning: true,
                    messages: false,
                    finances: true
                }
            }
        },
        methods: {
            async loadData() {
                try {
                    const res = await this.$http.get('/api/client/v1/users/email_notifications')
                    this.emailNotifications = res.data ?? {}
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async changeNotifications() {
                try {
                    await this.$http.post('/api/client/v1/users/email_notifications', this.emailNotifications)
                    this.$printSuccess(this.$t('print.success.data_updated'))
                    this.hide()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))

                }
            },
            show() {
                this.loadData()
                this.showModal = true
            },
            hide() {
                this.showModal = false
            }
        }
    }
</script>