<template>
    <b-card header-class="py-0" style="border-radius: 10px;" @click="viewMessage(message.id)" class="my-1 dark-card cursor-pointer" :class="{'unread': !message.read}" body-class="py-1">
        <b-row align-v="center">
            <b-col xl="6" cols="12">
                <div class="d-flex justify-content-start" style="font-size: 20px; color: black; margin-bottom: 0; padding-bottom: 5px; padding-top: 0.5rem;">
                    <div class="d-flex justify-content-center align-items-center mr-2" style="margin-right: 5px; border-radius: 14px; height: 28px!important; width: 28px!important; min-height: 28px!important; min-width: 28px!important;" :style="{backgroundColor: getPriority(message.priority).colour}">
                        <feather-icon size="16" class="icon-color" :class="{'text-unread': !message.read}" :icon="getPriority(message.priority).icon"/>
                    </div>
                    <span class="title" style="overflow-x: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ message.title }}</span>
                </div>
            </b-col>
            <b-col xl="6" cols="12">
                <div class="d-flex justify-content-xl-end justify-content-start">
                    <div class="d-flex justify-content-start align-items-center info" style="flex-wrap: wrap;">
                        <div class="" style="font-size: 14px; white-space: nowrap;">{{getAuthor(message)}}</div>
                        <div style="font-size: 14px; white-space: nowrap; margin-left: 5px; margin-right: 5px;">
                            | <feather-icon size="14" :icon="getType(message.type).icon"/> {{ getTypeName(message.type) }} |
                        </div>
                        <div class="" style="margin-right: 5px; font-size: 14px; white-space: nowrap;">{{getTime(message)}}</div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
    import {BCard, BCol, BRow} from 'bootstrap-vue'
    import {getVariant, getPriority, getType, getAuthor, getTypeName} from '@/libs/Messages'

    export default {
        components: {
            BCard,
            BRow,
            BCol
        },

        data() {
            return {
                getVariant,
                getPriority,
                getType,
                getTypeName,
                getAuthor
            }
        },

        props: {
            message: {
                type: Object,
                required: true
            }
        },

        methods: {
            viewMessage(id) {
                this.$router.push({name: 'system_message', params: {message_id: id}})
            },
            getTime(message) {
                if (message) {
                    return this.dayjs(message.created_at).format('DD.MM.YYYY H:m:ss')
                }

                return ''
            }
        }
    }
</script>

<style scoped>
.dark-layout .dark-card{
    background: #262626 !important;
    border-color: #292929!important;
    color: whitesmoke!important;
}

.dark-layout .title{
    color: whitesmoke!important;
}

.unread .title, .unread .info{
    font-weight: bold;
}

.dark-layout .dark-card.unread{
  border-bottom: 7px solid #808080 !important;
  background-color: #262626 !important;
}

.dark-card.unread {
    border-bottom: 7px solid darkgray!important;
    background-color: #F8F8F8!important;
}

.dark-card{
    background: #f2f2f3!important;
}

.icon-color {
    color: #6e6b7b;
}

.dark-layout .icon-color{
    color: #b4b7bd;
}
</style>